import image from "../rsc/Danilo.jpeg";

interface Person {
    firstName: string;
    lastName: string;
    image: string;
    email: string;
    website: string;
    instagram?: string;
    github?: string;
    linkedin?: string;
    xing?: string;
    medium?: string;
    city: string;
    locationHint?: string;
    state: string;
    country: string;
    preferredIdes: string[];
    favTec: string[];
    operatingSystems: string[];
    programs: string[];
    interests: string[];
    about: string[]; // Paragraphs about you
}

export const person:Person = {
    firstName: "Danilo",
    lastName: "Walenta",
    image: image,
    email: "contact@danilowalenta.com",
    website: "https://danilowalenta.com",
    github: "https://github.com/dannlos",
    linkedin: "https://www.linkedin.com/in/danilo-walenta/",
    instagram: "https://www.instagram.com/danilowalenta/",
    city: "Darmstadt",
    locationHint: "near Frankfurt",
    state: "Hesse",
    country: "Germany",
    preferredIdes: ["IntelliJ","PyCharm"],
    favTec: ["Python", "Java", "PHP", "HTML", "Tensorflow", "Google Cloud", "Docker", "git"],
    operatingSystems: ["Windows","Linux","macOS"],
    programs: ["MS Office","Power Bi","Rapidminer"],
    interests: ["Artificial Intelligence", "Digital Transformation","Climbing", "Cycle ball","Cycling", "Soccer","YMCA", "logic games"],
    about: ["My name is Danilo Walenta M.Sc., and I am a PhD Candidate and Research Associate at the Software and Digital Business Group at the Technical University of Darmstadt.",
        "My research interests encompass the analysis of the potential benefits and drawbacks of the digitalization movement on various industrial sectors, alongside conducting empirical investigations on contemporary issues regarding artificial intelligence from an economic standpoint.",
        "Besides my curiosity, I possess a keen aptitude to master new tasks with ease. Thus, I welcome all sorts of challenges. The challenges and obstacles are conquerable with my attitude of \"We will either find a way or make one!\" "]
}
